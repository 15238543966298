import { Form } from "antd";
import { FormInstance } from "antd/es/form";
import { TextAreaProps } from "antd/es/input";
import { FormItemProps } from "antd/lib/form";
import Input, { InputProps, PasswordProps } from "antd/lib/input";
import React, { useMemo, useRef } from "react";
import TierHubInput from "../07.inputs/TierHubInput";
import TierTextArea from "../26.text-area";
import "./styles.scss";
interface ITierHubFormItem extends FormItemProps<any> {
  form?: FormInstance<any>;
  regexOnChange?: RegExp;
  isRequired?: boolean;
  inputProps?: InputProps;
  inputRefValue?: string;
  textAreaProps?: TextAreaProps;
  textPasswordProps?: PasswordProps;
}

const TierHubFormItem: React.FC<ITierHubFormItem> = ({
  form,
  regexOnChange,
  children,
  label,
  isRequired,
  inputProps,
  textAreaProps,
  textPasswordProps,
  inputRefValue = "",
  className,
  ...props
}) => {
  const inputRef = useRef(inputRefValue);
  const { name } = props;

  const renderChildren = useMemo(() => {
    const handleCheckValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (regexOnChange) {
        if (regexOnChange?.test(value)) {
          inputRef.current = value;
          form?.setFieldValue(name ?? "", value);
        } else {
          if (value === "") {
            inputRef.current = value;
            form?.setFieldValue(name ?? "", value);
            return;
          }
          if (value.length === 1) inputRef.current = "";
          form?.setFieldValue(name ?? "", inputRef.current);
        }
      }
    };

    if (children) {
      return children;
    }

    if (textAreaProps) {
      return (
        <TierTextArea
          className="input-message"
          autoSize={{ minRows: 5, maxRows: 5 }}
          onChange={handleCheckValue as any}
          {...textAreaProps}
        />
      );
    }

    if (textPasswordProps) {
      return (
        <Input.Password {...textPasswordProps} onChange={handleCheckValue as any}></Input.Password>
      );
    }

    return <TierHubInput {...inputProps} onChange={handleCheckValue} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, textAreaProps, inputProps, regexOnChange, form, name]);

  return (
    <Form.Item
      className={`form-item ${className || className}`}
      label={
        label ? (
          <span className="title">
            {label} {isRequired ? <span className="title__star">*</span> : ""}
          </span>
        ) : undefined
      }
      {...props}
    >
      {renderChildren}
    </Form.Item>
  );
};

export default TierHubFormItem;
