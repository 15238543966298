import { UploadFile } from "antd";
import _ from "lodash";
import moment from "moment";
import { EPRODUCT_MEDIA_TYPE } from "src/constants";
import { compare2String } from "src/helpers";
import { ProductMedia, TraceabilityJourneys } from "src/types/product-item";
import { FormProduct, FormValues } from "../interface";

const defineCommonFields = (values: FormValues, dataJourneyId: number) => ({
  sendAndCollectionDate: moment(values.collectionDate).toISOString(),
  weight: values?.weight ? Number(values?.weight) : null,
  netWeight: values?.netWeight ? Number(values?.netWeight) : null,
  temperature: values?.temperature ? Number(values?.temperature) : null,
  vibration: values.vibration ? Number(values?.vibration) : null,
  humidity: values?.humidity ? Number(values?.humidity) : null,
  co2Emission: values?.co2Emission ? Number(values?.co2Emission) : null,
  waterConsumption: values?.waterConsumption ? Number(values?.waterConsumption) : null,
  energyConsumption: values?.energyConsumption ? Number(values?.energyConsumption) : null,
  description: values.description,
  traceabilityId: dataJourneyId,
  // location: values.receiveLocation,
});

// export const defineValueTemplate = (optionsTemplate: any, values: FormValues) => {
//   const res: FieldsTemplateMatch = getValueFromLabelOptions(values.template, optionsTemplate);
//   const valueTemplate = res?.fields?.map((item: FieldCustom, index) => {
//     let value = values[`${res.id}_${index}` as keyof FormValues];
//     if (item.dataType === ETemplateDataType.DATE_TIME) {
//       value = moment(value).toISOString();
//     }
//     return { ...item, values: value };
//   });
//   return {
//     name: res.name,
//     fields: valueTemplate,
//   };
// };

export const mapValuesToForm = (
  values: FormValues,
  currentStep: any,
  optionsTemplate: any,
  dataJourneyId: number,
) => {
  // let templateData = values?.cargoWiseIntegrate
  //   ? null
  //   : defineValueTemplate(optionsTemplate, values);

  const fieldMappings: Record<string, Record<string, any>> = {
    [FormProduct.Tier1_Journey_Form]: {
      ...defineCommonFields(values, dataJourneyId),
    },
    [FormProduct.Tier2_Journey_Form]: {
      ...defineCommonFields(values, dataJourneyId),
    },
    [FormProduct.Tier3_Journey_Form]: {
      ...defineCommonFields(values, dataJourneyId),
    },
    [FormProduct.Tier4_Journey_Form]: {
      ...defineCommonFields(values, dataJourneyId),
    },
    [FormProduct.Tier5_Journey_Form]: {
      ...defineCommonFields(values, dataJourneyId),
    },
  };

  const mapping = fieldMappings[currentStep.formProduct];
  if (!mapping) {
    return {};
  }

  return mapping;
};

export const getIdFromLabelOptions = (label: string, options: any) => {
  const selectedOption = options.find((option: any) => compare2String(option.name, label));
  return selectedOption ? selectedOption.id : label;
};

export const getValueFromLabelOptions = (label: string, options: any) => {
  const selectedOption = options.find((option: any) => compare2String(option.name, label));
  return selectedOption ? selectedOption : {};
};

export const customFilterOption = (inputValue: string, option: any) => {
  if (!option) {
    return false;
  }
  return option?.label?.toLowerCase().includes(inputValue.toLowerCase());
};

export const handleMapsMedia = (uploadFile: UploadFile[]) =>
  uploadFile.map((item) => ({ link: item.url, type: EPRODUCT_MEDIA_TYPE.IMAGE }));

export const fillForm = (product: TraceabilityJourneys) => {
  return {
    receiveLocation: product?.location ?? "",
    // dateCaught: product?.dateCaught ? moment(product?.dateCaught) : null,
    packAndSealDate: product?.packAndSealDate ? moment(product?.packAndSealDate) : null,
    collectionDate: product?.sendAndCollectionDate ? moment(product?.sendAndCollectionDate) : null,
    weight: product?.weight && Number(product?.weight) !== 0 ? Number(product?.weight) : null,
    netWeight:
      product?.netWeight && Number(product?.netWeight) !== 0 ? Number(product?.netWeight) : null,
    temperature: product?.temperature && Number(product?.temperature) !== 0 ? Number(product?.temperature) : null,
    vibration: product?.vibration && Number(product?.vibration) !== 0 ? Number(product?.vibration) : null,
    humidity: product?.humidity && Number(product?.humidity) !== 0 ? Number(product?.humidity) : null,
    co2Emission: product?.co2Emission && Number(product?.co2Emission) !== 0 ? Number(product?.co2Emission) : null,
    waterConsumption: product?.waterConsumption && Number(product?.waterConsumption) !== 0 ? Number(product?.waterConsumption) : null,
    energyConsumption: product?.energyConsumption && Number(product?.energyConsumption) !== 0 ? Number(product?.energyConsumption) : null,
    // grade: product?.grade,
    // packageForm: product?.packageForm,
    description: product?.description,
    seafoodDescription: product?.description,
    regionDescription: product?.regionDescription,
    cargoWiseIntegrate: product?.cargoWiseIntegrate,
    template: product.templateData?.name,
    cargoWiseProductID: product?.cargoWiseProductId,
    odooProductId: product?.odooProductId,
  };
};

export const formatValuesToUploadFileList = (values: ProductMedia[]): UploadFile<any>[] => {
  const newFileList: UploadFile<any>[] = values.map((item) => ({
    uid: item.id.toString(),
    name: item.link,
    status: "done",
    url: item.link || item.thumbnail || item.url,
    // type: item?.type?.toString(),
    typeImage: item?.type,
    thumbUrl: item?.thumbnail,
  }));

  return newFileList;
};

export const mediaFormBE = (logos: any, journeyMedia: ProductMedia[]) => {
  return _.reduce(
    logos,
    (result: any, item) => {
      const matchingMedia = _.find(journeyMedia, (journeyItem) => {
        return item.url && _.isEqual(item.uid, journeyItem.id.toString());
      });

      if (matchingMedia) {
        result.push(matchingMedia);
      }

      return result;
    },
    [],
  );
};

export const checkFileType = (url: string) => {
  if (url.match(/\.(jpg|png|JPG|PNG)$/)) {
    return EPRODUCT_MEDIA_TYPE.IMAGE;
  } else if (url.match(/\.(mp4|mov|MP4)$/)) {
    return EPRODUCT_MEDIA_TYPE.VIDEO;
  }
};

export const hasOnlyUndefinedValues = (obj: any) => {
  return _.every(obj, (value) => value === undefined);
};
