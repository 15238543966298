import { Modal, ModalProps } from "antd";
import { DEFAULT_MODAL_WIDTH } from "src/constants";
import { CloseCircle } from "src/assets/icons";
import "./styles.scss";

interface IModalProps extends ModalProps {
  children?: any;
  closeIcon?: any;
  centered?: boolean;
}

const TierHubModal: React.FC<IModalProps> = ({
  children,
  footer,
  width,
  className,
  centered = true,
  closeIcon = <img src={CloseCircle} alt="close-icon" />,
  ...props
}: IModalProps) => {
  return (
    <Modal
      centered={centered}
      width={width ? width : DEFAULT_MODAL_WIDTH}
      footer={footer ? footer : null}
      destroyOnClose={true}
      className={`tier-hub-modal-wrapper ${className || className} `}
      closeIcon={closeIcon}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default TierHubModal;
