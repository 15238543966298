import { LatLngAU, LatLngHK } from "src/constants";
import { getGeocode, getLatLng } from "use-places-autocomplete";

export const getLatLngByAddress = async (address: string) => {
  try {
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    if (lat !== undefined && lng !== undefined) {
      return { lat, lng };
    }
  } catch (error) {}
};
export const isWithinHongKong = (latitude: any, longitude: any) => {
  const { minLat, maxLat, minLng, maxLng } = LatLngHK;

  return latitude >= minLat && latitude <= maxLat && longitude >= minLng && longitude <= maxLng;
};

export const isWithinAustralia = (latitude: any, longitude: any) => {
  const { minLat, maxLat, minLng, maxLng } = LatLngAU;

  return latitude >= minLat && latitude <= maxLat && longitude >= minLng && longitude <= maxLng;
};
