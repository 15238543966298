import { ServiceBase } from "./core/service-base";
import { ProductListParams, ProductTypeCreateParams, ProductTypeListParams } from "./params-type";

export class ProductManagementService extends ServiceBase {
  // qr code related  
  getListProduct = async (params: ProductListParams) => {
    return this.get(`/products`, params);
  };

  getProductByQrCode = async (id: string) => {
    return this.get(`/products/by-qr-code/${id}`);
  };

  getProductDetail = async (id: number | string) => {
    return this.get(`/products/${id}`);
  };

  createProduct = async (payload: any) => {
    return this.post("/products/create-product", payload);
  };

  updateTraceability = async (productId: number | string, params: any) => {
    return this.put(`/traceability-jouneys/admin/${productId}`, params);
  };

  updateStatusProduct = async (productId: number | string) => {
    return this.put(`/products/update-status/${productId}`);
  };

  deleteQrCodeAdmin = async (ids: any) => {
    return this.delete2("/qr-codes/admin", ids);
  };

  exportProduct = async (params: ProductListParams) => {
    return this.getDataExport(`/products/export-xlxs`, params);
  };

  // product id related
  getSpecificProductType = async (id: number) => {
    return this.get(`/product_type/detail/${id}`)
  }
  getProductType = async (params: ProductTypeListParams) => {
    return this.get(`/product_type/list`, params)
  }

  importProductType = async (options: any, category: any, subCategory: any) => {
    return this.post(`/product_type/import`, { options, category, subCategory })
  }

  createProductType = async (categoryId: number, params: ProductTypeCreateParams) => {
    return this.post(`/product_type/create/${categoryId}`, params)
  }

  updateProductType = async (productTypeId: number, params: ProductTypeCreateParams) => {
    return this.put(`/product_type/${productTypeId}`, params)
  }

  toggleProductType = async (id: number) => {
    return this.put(`/product_type/toggle/${id}`)
  }

  createInventory = async (id: number, payload: any) => {
    return this.post(`/product_type/inventory/${id}`, payload);
  }
}

export const productManagementService = new ProductManagementService();
