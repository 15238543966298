import { Col, Modal, Row } from "antd";
import React from "react";
import ButtonContained from "../08.buttons/ButtonContainer";
import ButtonOutLined from "../08.buttons/ButtonOutLined";
import "./styles.scss";
interface PopupModal {
  title?: string;
  content?: React.ReactNode;
  id?: number | string;
  onCancel: () => void;
  className?: string;
  footer?: any;
  onSubmit: any;
  open: boolean;
  loading?: boolean;
  buttonName?: string;
  action?: "DELETE" | "APPROVE" | "VIEW" | "RESTORE" | string;
}
const ModalConfirm = (props: PopupModal) => {
  const {
    title,
    content,
    onCancel,
    onSubmit,
    open,
    id,
    buttonName,
    action,
    loading,
    className,
    footer,
  } = props;
  const handleSubmit = () => {
    onSubmit(id, action);
  };
  return (
    <Modal
      open={open}
      className={`modal-confirm ${className}`}
      centered
      title={title ? title : "Confirmation"}
      width={500}
      onCancel={onCancel}
      footer={
        footer
          ? footer
          : [
              <Row gutter={20} justify="center">
                <Col span={12}>
                  <ButtonOutLined
                    disabled={loading}
                    style={{ width: "100%" }}
                    size="large"
                    onClick={onCancel}
                  >
                    Cancel
                  </ButtonOutLined>
                </Col>
                <Col span={12}>
                  <ButtonContained
                    disabled={loading}
                    style={{ width: "100%" }}
                    onClick={handleSubmit}
                  >
                    {buttonName ? buttonName : "Confirm"}
                  </ButtonContained>
                </Col>
              </Row>,
            ]
      }
    >
      <div>{content ? content : "--"}</div>
    </Modal>
  );
};

export default ModalConfirm;
