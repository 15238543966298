import { FormInstance } from "antd";
import BigNumber from "bignumber.js";
import _ from "lodash";
import moment, { Moment } from "moment";
import { MESSAGES } from "src/constants/messages";
import { getLatLngByAddress, isWithinAustralia, isWithinHongKong } from "./maps";

export const validateRequired = async (value: string, message: string) => {
  if (!value?.trim()) {
    throw new Error(message);
  }
};

export const validateRequired2 = (value: string, message: string) => {
  if (!value?.trim()) {
    throw new Error(message);
  }
};

export const validateCharacter = (value: string, condition = 100, message: string) => {
  if (value?.trim()?.length > condition) {
    throw new Error(message);
  }
};

export const validateProductText = async (value: string, condition = 255, message: string) => {
  validateCharacter(value, condition, message);
};

export const validateProductName = async (_: any, value: string) => {
  const maxLength = 70;
  validateRequired2(value, "Please enter Product Name");
  validateCharacter(value, maxLength, `Product name should not exceed 70 characters`);
};

export const validateSeaFoodDescription = async (_: any, value: string) => {
  const maxLength = 255;
  validateCharacter(value, maxLength, MESSAGES.MC29);
};

export const validateSeaRegionDescription = async (_: any, value: string) => {
  const maxLength = 255;
  validateCharacter(value, maxLength, MESSAGES.MC30);
};

export const validateDescription = async (_: any, value: string) => {
  const maxLength = 255;
  validateCharacter(value, maxLength, MESSAGES.MC31);
};

const checkDayBeforeDay = (firstDay: Moment, secondDay: Moment) => {
  return firstDay.startOf("day").isBefore(secondDay.startOf("day"));
};

export const validateETDAndETA = async (value: string, form: FormInstance) => {
  const etdTime = form.getFieldValue("etdTime");
  const etaTime = etdTime ? moment(form.getFieldValue("etdTime")) : null;
  const packSealDate = moment(value);
  if (etaTime && packSealDate.isValid() && checkDayBeforeDay(packSealDate, etaTime)) {
    throw new Error("ETA should not be anterior to ETD");
  }
};

export const validatePackDate = async (value: string, form: FormInstance) => {
  const harvestDate = form.getFieldValue("harvestDate");
  const convertHarvestDate = harvestDate ? moment(form.getFieldValue("harvestDate")) : null;
  const packDate = moment(value);
  if (convertHarvestDate && packDate.isValid() && checkDayBeforeDay(packDate, convertHarvestDate)) {
    throw new Error("Pack & seal date should not be anterior to Harvest Date");
  }
};

export const validateSealDate = async (value: string, form: FormInstance) => {
  const harvestDate = form.getFieldValue("harvestDate");
  const convertHarvestDate = harvestDate ? moment(form.getFieldValue("harvestDate")) : null;
  const packDate = form.getFieldValue("packDate");
  const convertPackDate = packDate ? moment(form.getFieldValue("packDate")) : null;
  const sealDate = moment(value);
  if (convertHarvestDate && sealDate.isValid() && checkDayBeforeDay(sealDate, convertHarvestDate)) {
    throw new Error("Seal date should not be anterior to Harvest Date");
  }
  if (
    convertHarvestDate &&
    sealDate.isValid() &&
    checkDayBeforeDay(sealDate, convertPackDate as Moment)
  ) {
    throw new Error("Seal date should not be anterior to Pack Date");
  }
};

export const validateSendCollectionDate = async (
  value: string,
  form: FormInstance,
  prefix = "Send date",
) => {
  const valuePackDate = form.getFieldValue("packDate");
  // const valueSealDate = form.getFieldValue("sealDate");
  const valueDateCaught = form.getFieldValue("harvestDate");
  const caughtDate = valueDateCaught ? moment(valueDateCaught) : null;
  // const sealDate = valueSealDate ? moment(valueSealDate) : null;
  const packDate = valuePackDate ? moment(valuePackDate) : null;
  const collectionDate = moment(value);
  if (
    // (collectionDate.isValid() && sealDate && collectionDate,sealDate)) ||
    (caughtDate && checkDayBeforeDay(collectionDate, caughtDate)) ||
    (packDate && checkDayBeforeDay(collectionDate, packDate))
  ) {
    throw new Error(prefix + " should not be anterior to Pack & seal date and Harvest date");
  }
};

export const validateHumidity = async (value: number) => {
  isMoreThanValue(value, 100, MESSAGES.MC24);
};

export const validateWeight = async (value: number) => {
  const stringValue = value?.toString().replace(".", "");
  if (value && stringValue?.length > 10) {
    throw new Error(MESSAGES.MC21);
  }
  validateIsZero(value, MESSAGES.MC28);
};

export const validateIsZero = (value: number | string, message: string) => {
  if (new BigNumber(value).isZero()) {
    throw new Error(message);
  }
};

const isMoreThanValue = (value1: string | number, value2: string | number, message: string) => {
  if (value1 && value2 && new BigNumber(value1).gt(new BigNumber(value2))) {
    throw new Error(message);
  }
};

export const validateNumberQrCode = async (_: any, value: number | string) => {
  validateIsZero(value, "The minimum number of QR codes that can be created is 1");
  isMoreThanValue(value, 10, "The maximum number of QR codes that can be created at once is 10");
};

export const validate5Digit = async (
  value: number | string,
  numberDigits: number,
  message: string,
) => {
  const stringValue = value?.toString().replace(".", "");
  if (value?.toString()?.includes(".")) {
    if (value?.toString()?.split(".")[0]?.length > 5) {
      throw new Error(message);
    }
  } else if (value?.toString()?.length > 5) {
    throw new Error(message);
  } else if (stringValue?.length > numberDigits) {
    throw new Error(message);
  }
};
export const validate10Digit = async (
  value: number | string,
  numberDigits: number,
  message: string,
  messageZero: string,
) => {
  const stringValue = value?.toString().replace(".", "");
  if (value?.toString()?.includes(".")) {
    if (value?.toString()?.split(".")[0]?.length > 10) {
      throw new Error(message);
    }
  } else if (value?.toString()?.length > 10) {
    throw new Error(message);
  } else if (stringValue?.length > numberDigits) {
    throw new Error(message);
  }
  validateIsZero(value, messageZero);
};

export const validateInRange = async (
  value: number,
  range1: number,
  range2: number,
  message: string,
) => {
  if (value && !_.inRange(value, range1, range2)) {
    throw new Error(message);
  }
};

export const handleValidatePickLocation = async (address: string, label: string, isHK: boolean) => {
  if (!address) {
    throw new Error(`Please select ${label}`);
  }
  const location = await getLatLngByAddress(address);
  if (!location) {
    throw new Error("Region information unavailable, please select another location");
  }

  if (isHK) {
    if (!isWithinHongKong(location.lat, location.lng)) {
      throw new Error("Selected location needs to be within the Hong Kong map");
    }
  } else {
    if (!isWithinAustralia(location.lat, location.lng)) {
      throw new Error("Selected location needs to be within the Australia map");
    }
  }
};

export const validatePassword = async (rule: any, value: string) => {
  if (!value) {
    return;
  }

  if (value.length < 6 || value.length > 20) {
    return Promise.reject("Password should be 6-20 characters");
  }

  // Regular expressions to check for character types
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const digitRegex = /[0-9]/;
  const specialCharRegex = /[!@#$%^&*()_+[\]{};:'"<>,.?/~\\-]/;

  const missingTypes: string[] = [];
  if (!uppercaseRegex.test(value)) {
    missingTypes.push("Uppercase letters");
  }
  if (!lowercaseRegex.test(value)) {
    missingTypes.push("Lowercase letters");
  }
  if (!digitRegex.test(value)) {
    missingTypes.push("Numerical digits");
  }
  if (!specialCharRegex.test(value)) {
    missingTypes.push("Special characters");
  }

  if (missingTypes.length > 0) {
    return Promise.reject(`Password must contain: ${missingTypes.join(", ")}`);
  }

  return Promise.resolve();
};
