export const MESSAGES = {
  MC1: "Your code is incorrect",
  MC2: "Email/ Username or password is incorrect",
  MC3: "Network error",
  MC4: "Please connect to correct network",
  MC5: "Please connect your wallet to the correct network",
  MC6: "This field is required",
  MC7: "Invalid amount",
  MC8: "Insufficient amount, please check your wallet",
  MC9: "Transaction successfully",
  MC10: "Transaction error",
  MC11: "You dont have enough unallocated amount",
  MC12: "You do not have permission to view admin portal",
  MC13: "User refused to provide signature",
  MC14: "Please upload PNG, JPG, JPEG only",
  MC15: "Upload image should not exceed 10MB",
  MC16: "Product Information updated",
  MC17: "Product Information update failed",
  MC18: "Favourite group added",
  MC19: "Favourite group deleted",
  MC20: "Odoo product ID should not exceed 15 digits",
  MC21: "Weight should not exceed 10 digits",
  MC22: "Temperature should not exceed 5 digits",
  MC23: "Vibration should not exceed 5 digits",
  MC24: "Humidity should not exceed 100",
  MC25: "C02 emission should not exceed 5 digits",
  MC26: "Water consumption should not exceed 5 digits",
  MC27: "Energy consumption should not exceed 5 digits",
  MC28: "Weight can't be 0",
  MC29: "Seafood description should not exceed 255 characters",
  MC30: "Region description should not exceed 255 characters",
  MC31: "Description should not exceed 255 characters",
  MC32: "Upload video should not exceed 2MB",
  MC33: "Upload video should not exceed 5MB",
  MC34: "Upload video should not exceed 10MB",
  MC35: "Upload image should not exceed 2MB",
  MC36: "Upload image should not exceed 5MB",
  MC37: "Upload image should not exceed 10MB",
  MC38: "Volume out should not exceed 10 digits",
  MC39: "Volume in should not exceed 10 digits",
  MC40: "Product information recorded on the blockchain successfully",
  MC41: "Uploaded PDF should not exceed 10MB below the field",
  MC42: "Volume in can't be 0",
  MC43: "Volume out can't be 0",
};

export enum ERROR_MESSAGE {
  PASSWORD_UNCHANGED = "PASSWORD_UNCHANGED",
  OTHER_SYSTEM_ERROR = "OTHER_SYSTEM_ERROR",
  USER_REJECTED = "USER_REJECTED",
  USER_WAITING_APPROVE = "USER_WAITING_APPROVE",
  INVALID_TOKEN = "INVALID_TOKEN",
  EXISTED_EMAIL_USER = "EXISTED_EMAIL_USER",
  EXISTED_EMAIL_ADMIN = "EXISTED_EMAIL_ADMIN",
  REGISTERED_WITH_OTHER_METHOD = "REGISTERED_WITH_OTHER_METHOD",
  INVALID_THIRD_PARTY_TOKEN = "INVALID_THIRD_PARTY_TOKEN",
  NOT_SIGNUP_WITH_PASSWORD = "NOT_SIGNUP_WITH_PASSWORD",
  VERIFICATION_CODE_NOT_FOUND = "VERIFICATION_CODE_NOT_FOUND",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  NOT_FIRST_LOGIN = "NOT_FIRST_LOGIN",
  MS_NO_MAIL_LINKED = "MS_NO_MAIL_LINKED",
  EXISTED_CATEGORY_NAME = "EXISTED_CATEGORY_NAME",
  GROUP_NOT_APPROVABLE = "GROUP_NOT_APPROVABLE",
  NOT_OWNER_OF_GROUP = "NOT_OWNER_OF_GROUP",
  GROUP_NOT_FOUND = "GROUP_NOT_FOUND",
  NO_GROUP_OWNER = "NO_GROUP_OWNER",
  OWNER_TIER_MISMATCH = "OWNER_TIER_MISMATCH",
  NOT_ALLOW_TO_CREATE_PRODUCT = "NOT_ALLOW_TO_CREATE_PRODUCT",
  QR_CODE_USED = "QR_CODE_USED",
  QR_CODE_NOT_FOUND = "QR_CODE_NOT_FOUND",
  REQUIRE_TEMPLATE_DATA = "REQUIRE_TEMPLATE_DATA",
  UPLOAD_FAILED = "UPLOAD_FAILED",
  NOT_SUPER_ADMIN = "NOT_SUPER_ADMIN",
  EMAIL_IS_REVOKED = "EMAIL_IS_REVOKED",
  WRONG_EMAIL_OR_PASS = "WRONG_EMAIL_OR_PASS",
  PARENT_PRODUCT_NOT_FOUND = "PARENT_PRODUCT_NOT_FOUND",
  CATEGORY_NOT_FOUND = "CATEGORY_NOT_FOUND",
  LOGISTIC_GROUP_NOT_FOUND = "LOGISTIC_GROUP_NOT_FOUND",
  SELLER_GROUP_NOT_FOUND = "SELLER_GROUP_NOT_FOUND",
  PRODUCER_GROUP_NOT_FOUND = "PRODUCER_GROUP_NOT_FOUND",
  TEMPLATE_NOT_FOUND = "TEMPLATE_NOT_FOUND",
  INVALID_VERIFICATION_CODE = "INVALID_VERIFICATION_CODE",
  OWNER_ALREADY_HAS_BUSINESS = "OWNER_ALREADY_HAS_BUSINESS",
  SEND_MAIL_FAILED = "SEND_MAIL_FAILED",
  FORBIDDEN_RESOURCE = "FORBIDDEN_RESOURCE",
  ADMIN_NOT_FOUND = "ADMIN_NOT_FOUND",
  INVALID_TEMPLATE_INPUT = "INVALID_TEMPLATE_INPUT",
  TEMPLATE_REQUIRES_OPTIONS = "TEMPLATE_REQUIRES_OPTIONS",
  TEMPLATE_NOT_REQUIRE_OPTIONS = "TEMPLATE_NOT_REQUIRE_OPTIONS",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  COUNTRY_REGIONS_NOT_SUPPORTED = "COUNTRY_REGIONS_NOT_SUPPORTED",
  SEND_FEEDBACK_FAIL = "SEND_FEEDBACK_FAIL",
  FEEDBACK_NOT_FOUND = "FEEDBACK_NOT_FOUND",
  EXISTED_TRACEABILITY_JOURNEY = "EXISTED_TRACEABILITY_JOURNEY",
  TRACEABILITY_JOURNEY_NOT_FOUND = "TRACEABILITY_JOURNEY_NOT_FOUND",
  PREVIOUS_TIER_TRACE_NOT_FOUND = "PREVIOUS_TIER_TRACE_NOT_FOUND",
  USER_NOT_MATCH_GROUP = "USER_NOT_MATCH_GROUP",
  INVALID_PARENT_CATEGORY = "INVALID_PARENT_CATEGORY",
  PARENT_CATEGORY_NOT_FOUND = "PARENT_CATEGORY_NOT_FOUND",
  INVALID_PARENT_PRODUCT = "INVALID_PARENT_PRODUCT",
  EXISTED_EMPLOYEE_ID = "EXISTED_EMPLOYEE_ID",
  TRACEABILITY_JOURNEY_SUBMITTED = "TRACEABILITY_JOURNEY_SUBMITTED",
  INVALID_LATLNG = "INVALID_LATLNG",
  FAVORITE_GROUP_NOT_FOUND = "FAVORITE_GROUP_NOT_FOUND",
  CANNOT_DELETE_QR_CODE = "CANNOT_DELETE_QR_CODE",
  PARENT_PRODUCT_IS_NOT_SUBMITTED = "PARENT_PRODUCT_IS_NOT_SUBMITTED",
  TRACEABILITY_JOURNEY_IN_SUBMITTING = "TRACEABILITY_JOURNEY_IN_SUBMITTING",
  EXISTED_FAVORITE_GROUP = "EXISTED_FAVORITE_GROUP",
  CAMPAIN_TIMESTAMP_EXISTED = "CAMPAIN_TIMESTAMP_EXISTED",
  USER_REVOKED = "USER_REVOKED",
  GROUP_REVOKED = "GROUP_REVOKED",
  EXISTED_BUSINESS_NUMBER = "EXISTED_BUSINESS_NUMBER",
  CAMPAIGNS_NOT_EXISTED = "CAMPAIGNS_NOT_EXISTED",
  INVALID_LOCATION = "INVALID_LOCATION",
  REQUIRE_ALL_LOCATION_FIELDS = "REQUIRE_ALL_LOCATION_FIELDS",
  VALIDATION_ERROR = "VALIDATION_ERROR",
}

export enum MessageError {
  INVALID_PARENT_PRODUCT = "The parent product is already a child product of another product",
  USER_NOT_MATCH_GROUP = "The current user is not in the assigned group",
}

export const ERRORS = {
  REQUIRED: "This field is required",
  MAX_LENGTH: (maxLength: number) => `This field is max ${maxLength} charater`,
  NOT_EXCEED_2MB: "Image cannot exceed 2MB",
  NOT_ALLOW_TYPE_FILE: "Users can not upload the type file other svg, png, jpg",
};

export const formMessageCreateCategory = {
  required: "This field is required",
  string: {
    max: "This field is max 100 charater",
  },
};
