import { ServiceBase } from "./core/service-base";

export class UploadServices extends ServiceBase {
  uploadDocument = async (formData: any) => {
    return this.post("/upload/documents", formData);
  };

  uploadMultipleDocument = async (formData: any) => {
    
    return this.post("/upload/multiple-documents", formData);
  };
}
