import { Button, Col, Form, Input, Layout, Row, Select } from "antd";
import "./styles.scss";
import { Spinner } from "src/components/spinner";
import { useCallback, useEffect, useState } from "react";
import UploadMultiple from "src/components/33.upload-multiple";
import { UploadFile } from "antd/es/upload";
import UploadLogo from "src/components/27.upload-multiple-image";
import { EPRODUCT_MEDIA_TYPE, UPLOAD_SIZE_ALLOW } from "src/constants";
import { validateRequired } from "src/helpers/handleValidate";
import { CategoryParams } from "src/services/params-type";
import { CategoryService } from "src/services/category-service";
import ButtonContained from "src/components/08.buttons/ButtonContainer";
import { UploadServices } from "src/services/upload-service";
import { productManagementService } from "src/services/product-management";
import { toastMS } from "src/components/20.toast-message";
import { useHistory, useParams } from "react-router";
import { PATHS } from "src/constants/paths";
import { formatValuesToUploadFileList } from "src/pages/qr-management/product-detail/helper";

const INVENTORY_OPTIONS = [{
  label: 'Fishing Method',
  key: 'fishingMethod'
}, {
  label: 'Package Form',
  key: 'packageForm'
}, {
  label: 'Preparation',
  key: 'preparation',
  max: 2,
}, {
  label: 'Additional Attributes',
  key: 'additional',
  max: 2,
}, {
  label: 'Size/ Weight Code',
  key: 'weight',
}, {
  label: 'Grade',
  key: 'grade',
}]

const UpdateProductIdDetail = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [assets, setAssets] = useState<UploadFile[]>([]);
  const [categories, setCategories] = useState<{name: string, id: number}[]>([]);
  const [subCategories, setSubCategories] = useState<{name: string, id: number}[]>([]);
  const [regions, setRegions] = useState<{name: string, id: number}[]>([]);
  const [options, setOptions] = useState<{[x: string]: any[]}>({});

  const { id } = useParams<{ id: string }>();

  const fetchProductTypeDetail = async () => {
    const { data } = await productManagementService.getSpecificProductType(Number(id))
    const {
        category,
        productTypeNo,
        name,
        regionId,
        productMedia = [],
        productTypeToInventoryOption
    } = data
    form.setFieldValue("category", category?.parentId)
    form.setFieldValue("species", category?.id)
    form.setFieldValue("productTypeNo", productTypeNo)
    form.setFieldValue("name", name)
    if (regionId) form.setFieldValue("region", regionId)
    const parsedOptions = prepareInventoryOptions(productTypeToInventoryOption?.map(((
        item: {inventoryOption: any}) => item.inventoryOption
    )))
    Object.keys(parsedOptions).map((key) => {
        form.setFieldValue(key, parsedOptions[key])
    })
    
    setAssets(formatValuesToUploadFileList(productMedia))
    return data
  }

  const prepareInventoryOptions = (options?: {id: number, type: string, value: string}[]) => {
    if (!options) return {}
    let obj: {[x: string]: any } = {}
    options.forEach((item) => {
        if (obj[item.type]) {
            obj[item.type] = [...obj[item.type], item.id]
        } else {
            obj[item.type] = [item.id]
        }
    })
    return obj
  }
  useEffect(() => {
    fetchProductTypeDetail()
  }, [id])

  const categoryService = new CategoryService();
  const uploadService = new UploadServices();

  const history = useHistory();

  const categoryValue = Form.useWatch('category', form);

  const disabledSelector = useCallback(() => { return !!categoryValue }, [categoryValue])

  const uploadFiles = async (fileList: any, media?: boolean) => {
    const uploads = fileList.map(async (mapItem: any) => {
      if (mapItem.originFileObj) {
        const formData = new FormData();
        const file = mapItem.originFileObj;
        const type = /image/.test(mapItem?.type || mapItem?.typeImage);
        formData.append("files", file);
        const { data } = await uploadService.uploadMultipleDocument(formData);
        if (media) {
          if (!type) {
            return {
              url: data[0],
              type: EPRODUCT_MEDIA_TYPE.VIDEO,
              thumbnail: mapItem.thumbUrl,
            };
          } else {
            return {
              url: data[0],
              type: EPRODUCT_MEDIA_TYPE.IMAGE,
              thumbnail: "",
            };
          }
        } else {
          return {
            url: data[0],
            type: !type ? EPRODUCT_MEDIA_TYPE.VIDEO : EPRODUCT_MEDIA_TYPE.IMAGE,
            thumbnail: "",
          };
        }
      } else {
        return {
          link: mapItem.name || mapItem.url,
          url: mapItem.name || mapItem.url,
          type: mapItem.typeImage,
          thumbnail: mapItem.thumbUrl,
        };
      }
    });
    return Promise.all(uploads);
  };

  const onFinish = async (value: any) => {
    const {
      productTypeNo,
      name,
      region,
      species,
    } = value

    setLoading(true)
    let medias = []
    try {
      // upload files
      if (value?.media?.fileList) {
        if (value?.media?.fileList?.length > 0) {
          medias = await uploadFiles(value.media.fileList, true);
        } else {
          medias = [];
        }
      } else {
        const assets = form.getFieldValue("media");
        if (assets) {
          medias = assets?.map((item: any) => {
            return {
              link: item.name,
              type: item.typeImage,
              thumbnail: item.thumbUrl,
            };
          });
        }
      }  
    } catch(error) {
      setLoading(false)
      toastMS("error", "something wrong! cant upload image!")
      console.error("error >> ", error)
      return;
    }
    
    try {
      const inventoryOptionIds = INVENTORY_OPTIONS.map((item) => {
        const options = form.getFieldValue(item.key)
        return options
      })
  
      const payload = {
        regionId: region,
        name,
        productTypeNo,
        medias,
        inventoryOptionIds: inventoryOptionIds.flat()
      }
      await productManagementService.updateProductType(Number(id), payload)
    } catch(error) {
      setLoading(false)
      toastMS("error", "something went wrong! cant create product!")
      console.error("error >> ", error)
      return;
    }

    setLoading(false);
    history.push(PATHS.productManagement());
  }

  const onFinishFailed = (error: any) => {
  };

  const onSelect = (target: string, value: any, max?: number) => {
    if (max && form?.getFieldValue(target)?.length > max) {
      value.splice(0, 1);
    }
    form.setFieldValue(target, value)
  }

  const renderOptionSelector = (label: string, key: string, max?: number) => {
    return (
      <Form.Item
        name={key}
        label={label}
        rules={[
          {
            required: true,
            message: ""
          },
        ]}
      >
        <Select
          mode={max? "multiple": undefined}
          size="large"
          showSearch
          placeholder={`Select ${label}`}
          options={options[key] || []}
          onChange={(value) => onSelect(key, value, max)}
          disabled={!disabledSelector()}
        />
      </Form.Item>
    )
  }


  const fetchCategories = async () => {
    const { data } = await categoryService.listCategories();
    setCategories(data.map((item: { id: any; name: any; }) => ({ value: item.id, label: item.name })))
  };

  const fetchInventoryOptions = async (id: number) => {
    const { data = {} } = await categoryService.listInventoryOptions(id)

    const { options = {}, subCategory, regions: harvestAreas } = data

    setSubCategories(subCategory.map((item: { name: any; id: any; }) => ({ label: item.name, value: item.id })))
    setRegions(harvestAreas.map((item: { name: string; id: number; }) => ({label: item.name, value: item.id })))

    if (options?.category?.categoryToInventoryOption?.length > 0) {
      const optionsMapping: {[x: string]: any} = {}
      options.category.categoryToInventoryOption.forEach((item: any) => {
        const {
          inventoryOption
        } = item
      
        if (inventoryOption && inventoryOption.type) {
          const {
            type,
            value,
            id
          } = inventoryOption
          if (optionsMapping[type]) {
            optionsMapping[type].push({ label: value, value: id })
          } else {
            optionsMapping[type] = [{ label: value, value: id }]
          }
        }
      })
      setOptions(optionsMapping)
    }
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (categoryValue) fetchInventoryOptions(categoryValue)
  }, [categoryValue])

  return (
    <Layout className="container">
      <div>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          layout="vertical"
          className="form-product-id"
        >
          <Row gutter={16}>
            <Col span={12}>
              <div className="container-sub">
                <Row style={{ justifyContent: 'space-between' }}>
                  <Col>
                    <Row>
                      <text className="body1">Album</text>
                    </Row>
                    <Row>
                      <text className="body3--grey">
                        Image Dimensions (W x H) Recommendation: 1360x765px,<br/>
                        Upload PNG, JPEG, JPG only (Max. 2MB)<br/>
                        Video: Upload MP4 from 720p only (Max. 5MB)<br/>
                      </text>
                    </Row>
                  </Col>
                  <UploadLogo
                    isCanUploadVideo={true}
                    maxUploadableFiles={5}
                    nameMediaForm="media"
                    media={assets}
                    titleUpload="Upload a file (image/video)"
                    form={form}
                    ruleUpload={[]}
                    size={UPLOAD_SIZE_ALLOW.fiveMB}
                  />
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div className="container-sub">
                <Row>
                  <text className="body1">Detail</text>
                </Row>
                <div className="form-container">
                  <Form.Item
                    name="productTypeNo"
                    label="Product ID"
                  >
                    <Input size="large" placeholder="Product ID" disabled={true} />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label="Product Name"
                  >
                    <Input size="large" placeholder="Product Name" disabled={true} />
                  </Form.Item>
                  <Form.Item
                    name={'category'}
                    label={`Category`}
                  >
                    <Select
                      size="large"
                      showSearch
                      placeholder="Select category"
                      options={categories}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item
                    name={'species'}
                    label={`Species`}
                  >
                    <Select
                      size="large"
                      showSearch
                      placeholder="Select Species"
                      options={subCategories}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item
                    name={'region'}
                    label={`Harvest Area`}
                    rules={[
                      {
                        required: true,
                        message: ""
                      }
                    ]}
                  >
                    <Select
                      size="large"
                      showSearch
                      placeholder="Select Harvest Area"
                      options={regions || []}
                      onChange={(value) => onSelect('region', value)}
                      disabled={!disabledSelector()}
                    />
                  </Form.Item>
                  {
                    INVENTORY_OPTIONS.map((option) => renderOptionSelector(option.label, option.key, option.max))
                  }
                  <ButtonContained style={{ minWidth: "200px" }} htmlType="submit" disabled={loading}>
                    {"Confirm"}
                  </ButtonContained>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>
  );
};
export default UpdateProductIdDetail;
