import {
  CaretRightOutlined,
  CloseCircleFilled,
  EyeOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { UploadFile } from "antd";
import React from "react";
import { GalleryUpload, IconUpload, ThumbnailVideo } from "src/assets/icons";
import { EPRODUCT_MEDIA_TYPE } from "src/constants";
import "./styles.scss";

interface UploadItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  file: UploadFile<any>;
  handleOpenUploadThumbnail: any;
  handleRemove: any;
  handlePreviewVideo?: any;
  handlePreviewImage?: any;
  actions?: any;
}

const UploadItem = ({
  handlePreviewVideo,
  file,
  handleOpenUploadThumbnail,
  handlePreviewImage,
  handleRemove,
  actions,
}: UploadItemProps) => {
  const typeFile = (file: any) => {
    const isImage = /image/.test(file?.type || "");
    const isPDF = /application/.test(file?.type || "");
    if (isPDF || file?.typeImage === EPRODUCT_MEDIA_TYPE.PDF) {
      return EPRODUCT_MEDIA_TYPE.PDF;
    } else if (isImage || file?.typeImage === EPRODUCT_MEDIA_TYPE.IMAGE) {
      return EPRODUCT_MEDIA_TYPE.IMAGE;
    } else return EPRODUCT_MEDIA_TYPE.VIDEO;
  };

  const urlImage = () => {
    if (typeFile(file) === EPRODUCT_MEDIA_TYPE.VIDEO) {
      if (!file.thumbUrl) {
        return file.thumbUrl || ThumbnailVideo;
      } else {
        return file.thumbUrl || file.url || ThumbnailVideo;
      }
    } else if (typeFile(file) === EPRODUCT_MEDIA_TYPE.PDF) {
      return file.url || file.thumbUrl;
    } else {
      return file.url || file.thumbUrl;
    }
  };

  return (
    <div className="upload-item">
      {typeFile(file) === EPRODUCT_MEDIA_TYPE.PDF ? (
        <div className="upload-pdf">
          <FilePdfOutlined className="icon-pdf" />
          <span>{file.url || file.name}</span>
        </div>
      ) : (
        <img src={urlImage()} alt="uploadImage"></img>
      )}
      <div className="upload-item__frame">
        <CloseCircleFilled
          style={{ color: "#fff" }}
          className="upload-item__frame__remove"
          onClick={() => {
            actions?.remove(file);
            handleRemove(file);
          }}
        />
        {typeFile(file) === EPRODUCT_MEDIA_TYPE.IMAGE && (
          <EyeOutlined onClick={() => handlePreviewImage(file)} className="icon-preview" />
        )}

        {typeFile(file) === EPRODUCT_MEDIA_TYPE.VIDEO && file?.thumbUrl && (
          <CaretRightOutlined onClick={() => handlePreviewVideo(file)} className="icon-preview" />
        )}

        {typeFile(file) === EPRODUCT_MEDIA_TYPE.VIDEO && !file?.thumbUrl && (
          <div
            className="upload-item__frame__upload"
            onClick={() => handleOpenUploadThumbnail(file)}
          >
            <img src={GalleryUpload} alt="img"></img>
            Thumbnail
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadItem;
