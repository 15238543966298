import { FormInstance, UploadFile } from "antd";
import { ChangeEvent } from "react";
import { ETemplateDataType } from "src/constants";
import { Product } from "src/types/product-item";
import { Status, Suggestion } from "use-places-autocomplete";

export interface TierProductInterface {
  value?: string;
  status?: Status;
  data: Suggestion[];
  handleChangeReceiveLocation: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSelectReceiveLocation: (address: string) => void;
  getCurrentLocation: () => void;
  form: FormInstance<string>;
  formData: FormInstance<string>;
  cargoWiseIntegrate?: boolean;
  treeData?: any;
  fileList: UploadFile<any>[];
  setFileList?: any;
  optionsTemplate?: { value: string }[];
  defaultproductcategory?: any;
  isBlockchainRecord: boolean;
  productDetail?: Product;
  selectLocation?: { lat: number; lng: number } | null;
}

export interface FormValues {
  name: string;
  categoryId: number;
  subCategoryIds?: any;
  subCategories?: any;
  harvestDate?: string;
  productionDate?: string;
  packDate?: string;
  sealDate?: string;
  regionTemplateId?: number;
  grade?: string;
  packageForm?: number;
  preparation?: number;
  expiryDate?: string;
  volumeIn?: number;
  volumeOut?: number;
  logo?: any;
  location?: string;
  longitude?: number;
  latitude?: number;
  netWeight?: number;
  weight?: number;
  temperature?: number;
  vibration?: number;
  humidity?: number;
  co2Emission?: number;
  waterConsumption?: number;
  energyConsumption?: number;
  collectionDate?: string;
  receiveLocation?: string;
  traceabilityId?: number;

  dateCaught?: string;
  description?: string;
  regionDescription?: string;
  medias?: any;
  cargoWiseIntegrate?: true;
  cargoWiseProductId?: string;
  vesselName?: string;
  primaryMode?: string;
  producer?: string;
  carrier?: string;
  etdTime?: string;
  etaTime?: string;
  odooProductId?: string;
  restaurantDescription?: string;
  supplierDescription?: string;
  productLocationText?: string;
  productLocationLat?: number;
  productLocationLong?: number;
  receiveLocationText?: string;
  receiveLocationLat?: number;
  receiveLocationLong?: number;
  logisticsGroupId?: number;
  receiverGroupId?: number;
}

export interface StepData {
  title: string;
  component: React.FC<any>;
  // step: number;
  formProduct: FormProduct;
  stepTier4?: number;
  tierUpdate?: number;
  labelBackStep?: string;
  recordedOnBlockchain?: boolean;
}

export enum FormProduct {
  Tier1_Journey_Form = "tier1-journey-form",
  Tier2_Journey_Form = "tier2-journey-form",
  Tier3_Journey_Form = "tier3-journey-form",
  Tier4_Journey_Form = "tier4-journey-form",
  Tier5_Journey_Form = "tier5-journey-form",
}

export interface FieldsTemplateMatch {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  name: string;
  fields: FieldCustom[];
  creatorId: number;
}

export interface FieldCustom {
  fieldName: string;
  dataType: ETemplateDataType;
  isRequired?: boolean;
  options?: string[];
}

export interface OptionsSelect {
  value: string;
}
