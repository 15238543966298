import { Upload, UploadFile } from "antd";
import { FormInstance } from "antd/es/form";
import type { UploadProps } from "antd/es/upload";
import { Rule } from "antd/lib/form";
import { RcFile } from "antd/lib/upload";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { IconUpload } from "src/assets/icons";
import { UPLOAD_SIZE_ALLOW } from "src/constants";
import { MESSAGES } from "src/constants/messages";
import { UploadServices } from "src/services/upload-service";
import TierHubModal from "../05.modal/TierHubModal";
import TierHubFormItem from "../31.form-item";
import UploadItem from "../32.UploadItem";
import "./styles.scss";

const DEFAULT_MAX_FILES_UPLOAD = 5;
const DEFAULT_NAME_FORM_UPLOAD = "media";

interface ITierHubUploadProps {
  nameMediaForm?: any;
  size: number;
  maxUploadableFiles?: number;
  isCanUploadVideo?: boolean;
  ruleUpload?: Rule[];
  media: UploadFile[];
  form?: FormInstance;
  titleUpload?: string;
  customUploadButton?: any;
}
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const UploadLogo: React.FC<ITierHubUploadProps> = ({
  nameMediaForm = DEFAULT_NAME_FORM_UPLOAD,
  maxUploadableFiles = DEFAULT_MAX_FILES_UPLOAD,
  isCanUploadVideo = false,
  ruleUpload,
  media,
  form,
  size,
  titleUpload,
  customUploadButton
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewVideo, setPreviewVideo] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [urlPreviewVideo, setUrlPreviewVideo] = useState("");
  // const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uidVideo, setUidVideo] = useState("");
  const isHideUploadButton = fileList?.length >= maxUploadableFiles;
  const uploadService = new UploadServices();
  useEffect(() => {
    if (media) {
      setFileList(media);
    }
  }, [media]);

  const handlePreviewImage = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    // setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
  };

  const handlePreviewVideo = async (file: UploadFile) => {
    if (file?.originFileObj) {
      try {
        const blobUrl = URL.createObjectURL(file.originFileObj);
        setUrlPreviewVideo(blobUrl);
        setPreviewVideo(true);
      } catch (error) {
        console.error("Failed to create object URL:", error);
      }
    } else {
      setUrlPreviewVideo(file.url || "");
      setPreviewVideo(true);
    }
  };

  const beforeUpload = async (file: UploadFile) => {
    const isValid = !isCanUploadVideo
      ? file?.name.match(/\.(jpg|png|jpeg|JPEG|JPG|PNG)$/)
      : file?.name.match(/\.(jpg|png|jpeg|JPEG|JPG|PNG|mp4|mov|MP4)$/);

    const isImage = /image/.test(file?.type || "");
    const uploadSizeLimit = isImage ? size : UPLOAD_SIZE_ALLOW.tenMB;
    if (!isValid) {
      form?.setFields([
        {
          name: nameMediaForm,
          errors: ["Please upload PNG, JPG, MP4, MOV only"],
        },
      ]);
      // TierToast.error("Please upload PNG, JPG, MP4, MOV only");
      return Upload.LIST_IGNORE;
    }
    if (file.size && file?.size > uploadSizeLimit) {
      if (uploadSizeLimit === UPLOAD_SIZE_ALLOW.twoMB) {
        form?.setFields([
          {
            name: nameMediaForm,
            errors: [isImage ? MESSAGES.MC35 : MESSAGES.MC32],
          },
        ]);
        // TierToast.error(isImage ? MESSAGES.MC35 : MESSAGES.MC32);
      } else if (uploadSizeLimit === UPLOAD_SIZE_ALLOW.fiveMB) {
        form?.setFields([
          {
            name: nameMediaForm,
            errors: [isImage ? MESSAGES.MC36 : MESSAGES.MC33],
          },
        ]);
        // TierToast.error(isImage ? MESSAGES.MC36 : MESSAGES.MC33);
      } else if (uploadSizeLimit === UPLOAD_SIZE_ALLOW.tenMB) {
        form?.setFields([
          {
            name: nameMediaForm,
            errors: [isImage ? MESSAGES.MC37 : MESSAGES.MC34],
          },
        ]);
        // TierToast.error(isImage ? MESSAGES.MC37 : MESSAGES.MC34);
      }
      return Upload.LIST_IGNORE;
    } else {
      form?.setFields([
        {
          name: nameMediaForm,
          errors: [],
        },
      ]);
    }
  };

  const beforeUploadThumbnail = async (file: UploadFile) => {
    const isValid = file?.name.match(/\.(jpg|png|jpeg|JPEG|JPG|PNG)$/);

    // const uploadSizeLimit = 10 * 1024 * 1024;
    if (!isValid) {
      form?.setFields([
        {
          name: nameMediaForm,
          errors: ["Please upload PNG, JPG, JPEG"],
        },
      ]);
      // TierToast.error("Please upload PNG, JPG, JPEG");
      return Upload.LIST_IGNORE;
    }
    if (file.size && file?.size > size) {
      if (size === UPLOAD_SIZE_ALLOW.twoMB) {
        form?.setFields([
          {
            name: nameMediaForm,
            errors: [MESSAGES.MC35],
          },
        ]);
        // TierToast.error(MESSAGES.MC35);
      } else if (size === UPLOAD_SIZE_ALLOW.fiveMB) {
        form?.setFields([
          {
            name: nameMediaForm,
            errors: [MESSAGES.MC36],
          },
        ]);
        // TierToast.error(MESSAGES.MC36);
      } else if (size === UPLOAD_SIZE_ALLOW.tenMB) {
        form?.setFields([
          {
            name: nameMediaForm,
            errors: [MESSAGES.MC37],
          },
        ]);
        // TierToast.error(MESSAGES.MC37);
      }
      return Upload.LIST_IGNORE;
    }
  };

  const handleChange: UploadProps["onChange"] = async (info) => {
    try {
      const status = info.file.status;
      if (status === "removed") {
        return;
      }
      const newFileList: UploadFile[] = [
        ...fileList,
        {
          uid: info.file.uid,
          name: info.file.name,
          status: "done",
          percent: info.file.percent,
          originFileObj: info.file.originFileObj,
          type: info.file.type,
        },
      ];
      setFileList(_.uniqBy(newFileList, "uid"));
    } catch (error) {}
  };

  const handleChangeThumbnail: UploadProps["onChange"] = async (info: any) => {
    try {
      const status = info.file.status;
      if (status === "removed") {
        return;
      }

      const payload = new FormData();
      payload.append("file", info?.file.originFileObj);
      payload.append("type", "Logo");

      const { data } = await uploadService.uploadDocument(payload);
      const updatedFileList = fileList.map((item) =>
        item.uid === uidVideo ? { ...item, thumbUrl: data.url } : item,
      );
      const uniqByFileList = _.uniqBy(updatedFileList, "uid");
      setFileList(uniqByFileList);
      const logoData = form?.getFieldValue(nameMediaForm);
      const uploadFormat = {
        ...logoData,
        fileList: uniqByFileList,
      };
      form?.setFieldValue(nameMediaForm, uploadFormat);
    } catch (error) {}
  };

  const handleRemove = (file: UploadFile) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(newFileList);
    // form?.setFieldValue(nameMediaForm, newFileList);
  };

  const uploadButton = (
    <div>
      <img src={IconUpload} alt="" />
      <div className="upload-label">{titleUpload || "Upload"}</div>
    </div>
  );
  const handleOpenUploadThumbnail = async (file: any) => {
    setUidVideo(file?.uid);
    const upload = document?.getElementById("upload-test");
    upload?.click();
  };

  const renderUploadButton = () => {
    if (customUploadButton) {
      return (<Upload
          fileList={fileList}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          onRemove={handleRemove}
          onPreview={handlePreviewImage}
          itemRender={(originNode, file, fileList, actions) => (
            <UploadItem
              actions={actions}
              originNode={originNode}
              file={file}
              handleOpenUploadThumbnail={handleOpenUploadThumbnail}
              handleRemove={handleRemove}
              handlePreviewVideo={handlePreviewVideo}
              handlePreviewImage={handlePreviewImage}
            />
          )}
      >
        {customUploadButton}
      </Upload>)
    } else {
      return (<Upload
          className="upload-multiple"
          listType="picture-card"
          fileList={fileList}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          onRemove={handleRemove}
          onPreview={handlePreviewImage}
          itemRender={(originNode, file, fileList, actions) => (
            <UploadItem
              actions={actions}
              originNode={originNode}
              file={file}
              handleOpenUploadThumbnail={handleOpenUploadThumbnail}
              handleRemove={handleRemove}
              handlePreviewVideo={handlePreviewVideo}
              handlePreviewImage={handlePreviewImage}
            />
          )}
      >
        {!isHideUploadButton && uploadButton}
      </Upload>)
    }
  }

  return (
    <>
      <TierHubFormItem
        name={nameMediaForm}
        rules={
          ruleUpload
            ? ruleUpload
            : [
                {
                  validator: async (_, value) => {
                    if (fileList?.length > 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Please upload an image");
                    }
                  },
                },
              ]
        }
      >
        {
          renderUploadButton()
        }
      </TierHubFormItem>

      <TierHubFormItem style={{ display: "none" }} name="thumb-nail">
        <Upload
          accept="image/jpg, image/png, image/jpeg"
          id="upload-test"
          className="upload-multiple"
          listType="picture-card"
          beforeUpload={beforeUploadThumbnail}
          onChange={handleChangeThumbnail}
          onRemove={handleRemove}
          onPreview={handlePreviewImage}
        >
          {uploadButton}
        </Upload>
      </TierHubFormItem>
      <TierHubModal
        open={previewOpen}
        footer={null}
        onCancel={() => {
          setPreviewOpen(false);
        }}
        width={"auto"}
        className="preview-image-product"
        closeIcon={<></>}
      >
        {previewImage && (
          <img
            alt="example"
            style={{ width: "100%", maxHeight: "450px", objectFit: "scale-down" }}
            src={previewImage}
          />
        )}
      </TierHubModal>

      <TierHubModal
        open={previewVideo}
        footer={null}
        width={960}
        onCancel={() => {
          setPreviewVideo(false);
        }}
        className="preview-image-product"
        closeIcon={<></>}
      >
        {urlPreviewVideo && (
          <ReactPlayer width={"100%"} playing stopOnUnmount controls url={urlPreviewVideo} />
        )}
      </TierHubModal>
    </>
  );
};

export default UploadLogo;
