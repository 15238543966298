import { Button, Col, Form, Input, Layout, Row, Select } from "antd";
import "./styles.scss";
import { Spinner } from "src/components/spinner";
import { useCallback, useEffect, useState } from "react";
import UploadMultiple from "src/components/33.upload-multiple";
import { UploadFile } from "antd/es/upload";
import UploadLogo from "src/components/27.upload-multiple-image";
import { EPRODUCT_MEDIA_TYPE, UPLOAD_SIZE_ALLOW } from "src/constants";
import { validateRequired } from "src/helpers/handleValidate";
import { CategoryParams } from "src/services/params-type";
import { CategoryService } from "src/services/category-service";
import ButtonContained from "src/components/08.buttons/ButtonContainer";
import { UploadServices } from "src/services/upload-service";
import { productManagementService } from "src/services/product-management";
import { toastMS } from "src/components/20.toast-message";
import { useHistory } from "react-router";
import { PATHS } from "src/constants/paths";
import ModalConfirm from "src/components/05.modal";
import { ERROR_MESSAGE } from "src/constants/messages";

const INVENTORY_OPTIONS = [{
  label: 'Fishing Method',
  key: 'fishingMethod'
}, {
  label: 'Package Form',
  key: 'packageForm'
}, {
  label: 'Preparation',
  key: 'preparation',
  max: 2,
}, {
  label: 'Additional Attributes',
  key: 'additional',
  max: 2,
}, {
  label: 'Size/ Weight Code',
  key: 'weight',
}, {
  label: 'Grade',
  key: 'grade',
}]

const InventoryOptionDetail = () => {
  const [form] = Form.useForm();
  const [insertForm] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<{name: string, id: number}[]>([]);
  const [options, setOptions] = useState<{[x: string]: any[]}>({});
  const [searchTxt, setSearchText] = useState<{[x: string]: string}>({});
  
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const categoryService = new CategoryService();
  const history = useHistory();

  const categoryValue = Form.useWatch('category', form);

  const disabledSelector = useCallback(() => { return !!categoryValue }, [categoryValue])

  const onFinish = async (value: any) => {
    setLoading(true)
    try {
        INVENTORY_OPTIONS.forEach((optionType) => {
            let newOptions = options[optionType.key].filter(option => option.new)
            value[optionType.key] = value[optionType.key].map((val: number) => {
                const found = newOptions.find((option) => option.value === val)
                if (found) return {...found.extra}
                return val
            })
        })
        const {
            category,
            ...payload
        } = value
        await productManagementService.createInventory(value.category, {
            options: Object.values(payload).flat()
        })
    } catch (error: any) {
        console.error("error >> ", error)
        const msg = error?.response?.data;
        if (msg?.errorCode) {
            toastMS("error", "cant update inventory options")
        }
    }
    setLoading(false);
    toastMS("success", "update success")
    history.push(PATHS.productManagement());
  }

  const onFinishFailed = (error: any) => {
  };

  const onSelect = (target: string, value: any) => {
    form.setFieldValue(target, value)
  }

  const onSearch = (key: string, value: string) => {

    if (value.includes('#')) {
        const timeValue = new Date().valueOf()
        value = value.substring(0, value.length - 1);
        setOptions({
            ...options,
            [key]: [...options[key], { label: value, value: timeValue, new: true }]
        })
        form.setFieldValue(key, [...form.getFieldValue(key), timeValue])
        setSearchText({
            ...searchTxt,
            [key]: ''
        })
    } else {
        setSearchText({
            ...searchTxt,
            [key]: value
        })
    }
  }

  const renderOptionSelector = (label: string, key: string, max?: number) => {
    return (
      <Form.Item
        name={key}
        label={label}
        rules={[
          {
            required: true,
            message: ""
          },
        ]}
      >
        <Select
          mode={"multiple"}
          size="large"
          showSearch
          showArrow
          placeholder={`Select ${label}`}
          options={options[key] || []}
          onChange={(value) => onSelect(key, value)}
          disabled={!disabledSelector()}
          onSearch={(val) => onSearch(key, val)}
          searchValue={searchTxt[key]}
        />
      </Form.Item>
    )
  }


  const fetchCategories = async () => {
    const { data } = await categoryService.listCategories();
    setCategories(data.map((item: { id: any; name: any; }) => ({ value: item.id, label: item.name })))
  };

  const fetchInventoryOptions = async (id: number) => {
    const { data = {} } = await categoryService.listInventoryOptions(id)

    const { options = {} } = data

    if (options?.category?.categoryToInventoryOption?.length > 0) {
      const optionsMapping: {[x: string]: any} = {}
      options.category.categoryToInventoryOption.forEach((item: any) => {
        const {
          inventoryOption
        } = item
      
        if (inventoryOption && inventoryOption.type) {
          const {
            type,
            value,
            id
          } = inventoryOption
          if (optionsMapping[type]) {
            optionsMapping[type].push({ label: value, value: id })
          } else {
            optionsMapping[type] = [{ label: value, value: id }]
          }
        }
      })
      setOptions(optionsMapping)
      INVENTORY_OPTIONS.map((item) => {
        form.setFieldValue(item.key, optionsMapping?.[item.key]?.map((option: { value: any; }) => option.value))
      })
    }
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (categoryValue) fetchInventoryOptions(categoryValue)
  }, [categoryValue])

  const handleAction = async () => {
    try {
        await insertForm.validateFields()
        const values = insertForm.getFieldsValue()
        const timeValue = new Date().valueOf()
        setOptions({
            ...options,
            [values.key]: [
                ...(options[values.key] || []),
                {
                    label: values.value,
                    value: timeValue,
                    new: true,
                    extra: {
                        type: values.key,
                        value: values.value,
                        shortForm: values.shortForm
                    }
                }
            ]
        })
        form.setFieldValue(values.key, [...(form.getFieldValue(values.key) || []), timeValue])
        setIsOpenModal(false)
    } catch(error) {
        console.log("error >> ", error)
        toastMS("error", "Please ensure all fields are correct!")
    }
  }

  const generatePopupContent = () => (
    <Form
        form={insertForm}
        layout="vertical"
        className="form-inventory-option"
    >
        <Form.Item
            name={'key'}
            label={`Field`}
            rules={[
                {
                required: true,
                message: ""
                }
            ]}
        >
            <Select
                size="large"
                showSearch
                placeholder="Select Field"
                options={INVENTORY_OPTIONS.map((item: {label: string, key: string}) => ({ label: item.label, value: item.key }))}
            />
        </Form.Item>
        <Form.Item
            name="value"
            label="Value"
            rules={[
                {
                required: true,
                message: "",
                },
                { max: 70, message: "Value must be between 1-70 characters" },
            ]}
            >
            <Input size="large" placeholder="Value" />
        </Form.Item>
        <Form.Item name="shortForm" label="Short Form">
            <Input size="large" placeholder="Short Form" />
        </Form.Item>
    </Form>
  )

  return (
    <Layout className="container">
      <div>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          layout="vertical"
          className="form-inventory-option"
        >
            <div className="container-sub">
                <Row>
                    <text className="body1">Category</text>
                    <Button className="right-button" onClick={() => setIsOpenModal(true)}>
                        Add Options
                    </Button>
                </Row>
                <div className="form-container">
                    <Form.Item
                        name={'category'}
                        label={`Category`}
                        rules={[
                            {
                            required: true,
                            message: ""
                            }
                        ]}
                    >
                        <Select
                            size="large"
                            showSearch
                            placeholder="Select category"
                            options={categories}
                            onChange={(value) => onSelect('category', value)}
                        />
                    </Form.Item>
                    {
                        INVENTORY_OPTIONS.map((option) => renderOptionSelector(option.label, option.key, option.max))
                    }
                    <ButtonContained style={{ minWidth: "200px" }} htmlType="submit" disabled={loading}>
                        {"Confirm"}
                    </ButtonContained>
                </div>
            </div>
        </Form>
      </div>

      <ModalConfirm
        open={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        onSubmit={handleAction}
        content={generatePopupContent()}
      />
    </Layout>
  );
};
export default InventoryOptionDetail;
