import { ServiceBase } from "./core/service-base";
import { CategoryParams } from "./params-type";

export class CategoryService extends ServiceBase {
  createCategory = async (body: any) => {
    return this.post("/categories/create-category", body);
  };

  createSubCategory = async (body: any) => {
    return this.post("/categories/create-sub-category", body);
  };

  listCategories = async () => {
    return this.get("/categories/list");
  }

  listInventoryOptions = async (categoryId: number) => {
    return this.get(`/categories/options/${categoryId}`)
  }

  getCategories = async (params?: CategoryParams) => {
    return this.get("/categories/admin-categories", params);
  };

  getAllCategories = async (params?: CategoryParams) => {
    return this.get("/categories", params);
  };
  getSubCategories = async (params?: CategoryParams) => {
    return this.get("/categories/sub-categories", params);
  };

  getChildrenCategory = async (id: string, params?: CategoryParams) => {
    return this.get(`/categories/child-categories/${id}`, params);
  };

  getChildrenSubCategory = async (id: string, params?: CategoryParams) => {
    return this.get(`/categories/admin-product-sub-categories/${id}`, params);
  };

  getCategoryDetail = async (id: string) => {
    return this.get(`/categories/${id}`);
  };
  updateCategory = async (id: string, body: any) => {
    return this.put(`/categories/category/${id}`, body);
  };
  updateSubCategory = async (id: string, body: any) => {
    return this.put(`/categories/sub-category/${id}`, body);
  };
  showHide = async (id: string) => {
    return this.put(`/categories/${id}/show-type`, {});
  };
  deleteCategory = async (id: Category["id"]) => {
    return this.delete("/admins/categories", id);
  };
  getCategoriesTree = async (params?: CategoryParams) => {
    return this.get("/categories/tree", params);
  };

  getCategoriesById = async (id: string | number) => {
    return this.get(`/categories/${id}`);
  };

  importCategory = async (body: any) => {
    return this.post(`/categories/import`, body)
  }
}
